<template>
  <div
    class="my-5 py-5"
    :style="{
      backgroundImage:
        'url(' + require('../../assets/images/background-login.jpg') + ')'
    }"
    id="loginArea"
  >
    <div class="container">
      <div class="row justify-content-center">
        <div class="auth-wrapper">
          <div class="card overflow-hidden" style="background: transparent">
            <div class="card-title card-header px-0">
              <img src="@/assets/logo-dark.png" height="50" />
            </div>
            <div class="card-sub-title">
              <div class="sub-title-left">
                <h5>Seja bem-vindo!</h5>
                <small>Faça o login para acessar a nossa plataforma</small>
              </div>
              <div class="sub-title-right">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="50"
                  height="50"
                  viewBox="0 0 73.399 73.399"
                >
                  <g id="login" transform="translate(0)" opacity="0.245">
                    <g
                      id="Grupo_81"
                      data-name="Grupo 81"
                      transform="translate(0)"
                    >
                      <g id="Grupo_80" data-name="Grupo 80">
                        <path
                          id="Caminho_394"
                          data-name="Caminho 394"
                          d="M18.852,67.665H8.7a2.913,2.913,0,0,1-2.294-1.09A3,3,0,0,1,5.8,64.063,31.678,31.678,0,0,1,35.969,38.978c.243.009.486.015.731.015s.492-.006.737-.015a31.4,31.4,0,0,1,10.356,2A2.867,2.867,0,1,0,49.808,35.6q-.712-.268-1.433-.5a19.5,19.5,0,1,0-23.339.01A37.285,37.285,0,0,0,.186,62.895,8.711,8.711,0,0,0,8.7,73.4H18.852a2.867,2.867,0,1,0,0-5.734ZM22.938,19.5A13.762,13.762,0,1,1,37.37,33.243c-.223,0-.446-.007-.67-.007s-.448,0-.672.007A13.779,13.779,0,0,1,22.938,19.5Z"
                          transform="translate(0)"
                          fill="#a5dcff"
                        />
                        <path
                          id="Caminho_395"
                          data-name="Caminho 395"
                          d="M196.274,317.531a7.726,7.726,0,0,0-7.05-4.531h-8.961a7.59,7.59,0,0,0-7.172,4.847c-.151.392-.335.886-.527,1.461H153.792a2.868,2.868,0,0,0-2.057.87l-4.925,5.073a2.867,2.867,0,0,0,.012,4.007l5.018,5.105a2.867,2.867,0,0,0,2.045.857H163.2a2.867,2.867,0,1,0,0-5.734h-8.116l-2.212-2.25,2.13-2.194h19.71a2.867,2.867,0,0,0,2.785-2.185,23.692,23.692,0,0,1,.944-2.953,1.879,1.879,0,0,1,1.827-1.17h8.947a1.956,1.956,0,0,1,1.821,1.137,18.516,18.516,0,0,1,1.7,7.309,19.063,19.063,0,0,1-1.683,7.439,1.957,1.957,0,0,1-1.8,1.174H180.2a2.041,2.041,0,0,1-1.849-1.27,19.062,19.062,0,0,1-.888-2.838,2.867,2.867,0,1,0-5.577,1.335,24.68,24.68,0,0,0,1.164,3.69,7.734,7.734,0,0,0,7.143,4.818h9.068a7.687,7.687,0,0,0,7.05-4.616,24.819,24.819,0,0,0,2.161-9.745A24.274,24.274,0,0,0,196.274,317.531Z"
                          transform="translate(-125.069 -268.129)"
                          fill="#a5dcff"
                        />
                        <circle
                          id="Elipse_13"
                          data-name="Elipse 13"
                          cx="3"
                          cy="3"
                          r="3"
                          transform="translate(58.7 56.7)"
                          fill="#a5dcff"
                        />
                      </g>
                    </g>
                  </g>
                </svg>
              </div>
            </div>
            <div class="card-body bg-white">
              <b-alert v-model="isAuthError" variant="danger" dismissible>
                {{ authError }}
              </b-alert>

              <b-form @submit.prevent="tryToLogIn">
                <b-form-group
                  id="email-input-group"
                  label="Usuário"
                  label-for="email-input"
                >
                  <b-form-input
                    id="email-input"
                    v-model="email"
                    type="text"
                    placeholder="Nome de usuário ou endereço de e-mail"
                    :class="{ 'is-invalid': submitted && $v.email.$error }"
                  />

                  <div
                    v-if="submitted && $v.email.$error"
                    class="invalid-feedback"
                  >
                    <span v-if="!$v.email.required"> Campo obrigatório </span>
                    <span v-if="!$v.email.email">
                      Por favor, digite um e-mail válido
                    </span>
                  </div>
                </b-form-group>

                <b-form-group
                  id="password-input-group"
                  label="Senha"
                  label-for="password-input"
                >
                  <b-form-input
                    id="password-input"
                    v-model="password"
                    type="password"
                    placeholder="Digite a senha"
                    :class="{ 'is-invalid': submitted && $v.password.$error }"
                  />

                  <div
                    v-if="submitted && !$v.password.required"
                    class="invalid-feedback"
                  >
                    Campo obrigatório
                  </div>
                </b-form-group>

                <div class="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    id="remember-me-input"
                    class="custom-control-input"
                    v-model="rememberMe"
                  />
                  <label class="custom-control-label" for="remember-me-input">
                    Lembre-me
                  </label>
                </div>

                <div class="mt-3 text-center">
                  <b-button
                    pill
                    type="submit"
                    variant="primary"
                    class="font-weight-bold"
                    style="padding: 12px 62px"
                    v-if="!loading"
                  >
                    Entrar
                  </b-button>

                  <b-spinner
                    variant="primary"
                    label="Carregando"
                    class="mt-1"
                    v-if="loading"
                  />
                </div>

                <div class="mt-4 text-center">
                  <router-link
                    :to="{ name: 'forgotPassword' }"
                    class="text-muted"
                  >
                    <i class="fas fa-lock mr-1"></i> Esqueci minha senha
                  </router-link>
                </div>
              </b-form>
            </div>
            <br />
            <p style="text-align: center">
              &copy; {{ actualYear }} - SEGASP Univalores
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import { createNamespacedHelpers } from 'vuex';
import router from '@/router';

const { mapActions } = createNamespacedHelpers('auth');
let vm;
export default {
  name: 'LoginPageBroker',
  data() {
    return {
      email: '',
      password: '',
      submitted: false,
      authError: null,
      loading: false,
      isAuthError: false,
      rememberMe: false
    };
  },
  mixins: [validationMixin],
  validations: {
    email: { required },
    password: { required }
  },
  mounted() {
    vm = this;
  },
  methods: {
    ...mapActions(['LOGIN']),
    tryToLogIn() {
      this.submitted = true;
      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      }

      this.loading = true;

      this.LOGIN({
        login: this.email,
        senha: this.password,
        loggedAs: null
      })
        .then((_response) => {
          if (_response.isAuthenticated) {
            router.replace({
              name: 'home'
            });
          } else {
            this.isAuthError = true;
            this.authError = vm.$t('login.invalid');
          }
        })
        .catch((_error) => {
          this.authError = _error;
          this.isAuthError = true;
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>

<style>
#loginArea {
  margin: 0 !important;
  min-height: 100vh;

  background-repeat: no-repeat;
  background-size: cover;
  background-position: right top;
}

#loginArea .card-title {
  background: transparent;
  margin: 0;
}

#loginArea .card-sub-title {
  background-color: var(--primary);
  height: 100px;
  margin: 0px;
}

#loginArea .card-sub-title h5 {
  font-size: 1.5rem;
}

#loginArea .card-sub-title .sub-title-left {
  float: left;
  padding-left: 20px;
}
#loginArea .card-sub-title .sub-title-right {
  display: none;
  float: right;
  padding: 20px 20px 0 0;
}
#loginArea .card-sub-title h5 {
  padding: 20px 0 0 0;
}
#loginArea .card-sub-title h5,
#loginArea .card-sub-title small {
  color: #fff !important;
}

#loginArea .social-box {
  margin-top: 42px;
}

#loginArea .social-box .social-icon {
  width: 32px;
  height: 32px;
  margin: 0 4px;
  border-radius: 16px;
}

@media (min-width: 470px) {
  #loginArea .card-sub-title .sub-title-right {
    display: block;
  }
}
</style>

<style lang="scss" scoped>
@import '@/design/index.scss';

.auth-wrapper {
  width: 90%;
  max-width: 450px;
}

.card-header {
  background: $sidebar-dark-bg;
  text-align: center;
  padding: 20px;
}
</style>
