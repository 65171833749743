import { render, staticRenderFns } from "./LoginPageBroker.vue?vue&type=template&id=532214ef&scoped=true&"
import script from "./LoginPageBroker.vue?vue&type=script&lang=js&"
export * from "./LoginPageBroker.vue?vue&type=script&lang=js&"
import style0 from "./LoginPageBroker.vue?vue&type=style&index=0&lang=css&"
import style1 from "./LoginPageBroker.vue?vue&type=style&index=1&id=532214ef&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "532214ef",
  null
  
)

export default component.exports